import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["typeField", "numberField"]

  connect() {
    this.typeFieldTargets.forEach(element => {
      this.toggleNumberField(element);
    })
  }

  toggleNumberField(element) {
    const fieldName = element.dataset.fieldName;
    const isNumber = element.value === 'number';
    const numberField = this.numberFieldTargets.find(field => field.dataset.fieldName === fieldName);

    if (numberField) {
      numberField.readOnly = !isNumber;
      if (!isNumber) {
        numberField.value = '';
        numberField.dispatchEvent(new Event("change"));
      }
    }
  }

  onTypeChange(event) {
    this.toggleNumberField(event.currentTarget);
  }
}
